import _ from 'lodash'
import {getBatteryProfileDetails, getSiteSettingsDetails} from '../store'
import {
  BATTERY_GRID_MODE,
  COUNTRY_CODE,
  DEVICE_TYPE,
  PROFILE_TYPE,
  REGION,
  SITE_STATUS,
  TOGGLE_SWITCH_STATUS,
} from './constants'
import {getDeviceDetails, isWeb} from './utility'
import {isFeatureSupported, FEATURE_KEYS} from './featureKeys'

/* *********************** *********************** *********************** *
 * Site Types
 * *********************** *********************** *********************** */
// Site has Production Meter
export function siteHasPv() {
  return getSiteSettingsDetails().showProduction
}

// Site has Consumption Meter
export function siteHasCt() {
  return getSiteSettingsDetails().showConsumption
}

// Is PV only site
export function isPVOnlySite() {
  return !getSiteSettingsDetails().hasEncharge
}

// Site has Battery
export function siteHasBattery() {
  return getSiteSettingsDetails().hasAcb || getSiteSettingsDetails().hasEncharge
}

// Tariff - Time of Use selected
export function isTouSelected() {
  return getBatteryProfileDetails().isTariffTou
}

// Site has Enpower
export function hasEnpower() {
  return getSiteSettingsDetails().hasEnpower
}

// Site has Encharge
export function hasEncharge() {
  return getSiteSettingsDetails().hasEncharge
}

// Is DT supported site
export function isDTSupportedSite() {
  return getSiteSettingsDetails()?.isDTSite
}

// Is Hems Activation Pending
export function isHemsActivationPending() {
  return getSiteSettingsDetails()?.isHemsActivationPending
}

// Is DT enabled site
export function isDTEnabledSite() {
  return getSiteSettingsDetails().isDTEnabled
}

// Is Hems site
export function isHemsSite() {
  return getSiteSettingsDetails().isHemsSite
}

// Has Hems devices
export function hasHemsDevices() {
  return getBatteryProfileDetails().devices?.thirdPartyEvse?.length > 0
}

// Get device type
export function getDeviceType() {
  // NOTE: Temporary comment this code
  if (isHemsSite()) return DEVICE_TYPE.THIRD_PARTY_EVSE
  return DEVICE_TYPE.IQ_EVSE
}

// Get Gateway not reporting
export function isGatewayNotReporting() {
  if (!_.isEmpty(getSiteSettingsDetails().siteStatus)) {
    const {code} = getSiteSettingsDetails().siteStatus
    if (code === SITE_STATUS.COMM) return true
  }

  return false
}

export function isSavingProfile() {
  if (
    getBatteryProfileDetails()?.requestedConfigMqtt?.profile === PROFILE_TYPE.COST_SAVINGS ||
    getBatteryProfileDetails()?.requestedConfig?.profile === PROFILE_TYPE.COST_SAVINGS ||
    getBatteryProfileDetails()?.profile === PROFILE_TYPE.COST_SAVINGS
  ) {
    return true
  }

  return false
}

// Is self consumption profile
export function isSelfConsumptionProfile() {
  if (
    getBatteryProfileDetails()?.requestedConfigMqtt?.profile === PROFILE_TYPE.SELF_CONSUMPTION ||
    getBatteryProfileDetails()?.requestedConfig?.profile === PROFILE_TYPE.SELF_CONSUMPTION ||
    getBatteryProfileDetails()?.profile === PROFILE_TYPE.SELF_CONSUMPTION
  ) {
    return true
  }

  return false
}

// Show GIC disclaimer dialog
export function isShowGicDisclaimer(toggleStatus, batteryDetails) {
  if (
    toggleStatus === TOGGLE_SWITCH_STATUS.ON &&
    !batteryDetails?.chargeFromGrid &&
    isDTSupportedSite() &&
    hasEncharge() &&
    !getAcceptedGICDisclaimer() &&
    (isSelfConsumptionProfile() || isSavingProfile())
  ) {
    return true
  }
  return false
}

export function disableCFGForDT(cfgStatus) {
  return isDTSupportedSite() && isDTEnabledSite() && isSavingProfile() && cfgStatus === TOGGLE_SWITCH_STATUS.ON
}

// EVSE Site
export function isEvseSite() {
  return getBatteryProfileDetails().devices?.iqEvse?.length > 0
}

export function isEvseSiteForBattery() {
  return getBatteryProfileDetails().devices?.iqEvse?.useBatteryFrSelfConsumption !== undefined
}

// CT + Battery
export function siteHasCtAndBattery() {
  return siteHasCt() && siteHasBattery()
}

// Enpower + Without PV + CT + Battery
export function isCtAndBatteryWithEnpowerSite() {
  return !siteHasPv() && siteHasCtAndBattery() && hasEnpower()
}

// EVSE + PV + CT + Without Battery
export function isPvAndCtWithEvseSite() {
  return siteHasPv() && siteHasCt() && !siteHasBattery() && isEvseSite()
}

// EVSE + PV + CT + Battery
export function isPvCtAndBatteryWithEvseSite() {
  return siteHasPv() && siteHasCt() && siteHasBattery() && isEvseSite()
}

// PV + CT + Battery
export function isPvCtBatterySite() {
  return hasEncharge() && hasEnpower()
}

/* *********************** *********************** *********************** *
 * System Type
 * *********************** *********************** *********************** */

// Grid Agnostic System
export function isSiteGridAgnostic() {
  return hasEnpower() && !isIQCollarPresentNotInstalled()
}

// Grid Tied System
export function isSiteGridTied() {
  return !(hasEnpower() && !isIQCollarPresentNotInstalled())
}

// Device Behaviour
export function hasSystemCard() {
  return (
    getBatteryProfileDetails().devices?.iqEvse?.length > 0 ||
    getBatteryProfileDetails().devices?.thirdPartyEvse?.length > 0
  )
}

export function isCoExistingSite() {
  return isEvseSite() && isHemsSite()
}

/* *********************** *********************** *********************** *
 * Other Functions
 * *********************** *********************** *********************** */

// Get Site Id
export function getSiteId() {
  return getDeviceDetails('siteId', 'siteId')
}

// Get site country code
export function getSiteCountryCode() {
  // return COUNTRY_CODE.US
  return getSiteSettingsDetails().countryCode
}

// Get site country region
export function getCountryRegion() {
  // return REGION.NA
  return getSiteSettingsDetails().region
}

// Checks for a FR country site or NA region site
export function isSiteInNAorFR() {
  return hasEncharge() && (getSiteCountryCode() === COUNTRY_CODE.FR || getCountryRegion() === REGION.NA)
}

// Checks for a FR country site
export function isSiteInFR() {
  return hasEncharge() && getSiteCountryCode() === COUNTRY_CODE.FR
}

// Checks for site country US
export function isCountryUS() {
  return getSiteCountryCode() === COUNTRY_CODE.US
}

// Checks for site country AU
export function isCountryAU() {
  return getSiteCountryCode() === COUNTRY_CODE.AU
}

// Checks for site country DE
export function isCountryDE() {
  return getSiteCountryCode() === COUNTRY_CODE.DE
}

// Get site timezone
export function getSiteTimezone() {
  return getSiteSettingsDetails().timezone
}

/* *********************** *********************** *********************** *
 * Show / Hide Components
 * *********************** *********************** *********************** */

// Show Storm Guard
export function showStormGuard() {
  return getSiteSettingsDetails().showStormGuard
}

// Show Savings Mode
export function showSavingsMode() {
  return getSiteSettingsDetails().showSavingsMode
}

// Show charge battery from grid
export function showChargeFromGrid() {
  return getBatteryProfileDetails().cfgControl.show
}

export function isIQGWScheduleSupportedSite() {
  return getSiteSettingsDetails().isIQGWScheduleSupported
}

// checks if EnsembleSite
export function isEnsembleSite() {
  return getSiteSettingsDetails().isEnsemble
}

// Checks if Savings mode is disabled for EMEA
export function isSavingsModeDisabledForEmea() {
  return getSiteSettingsDetails().isEmea
}

// Checks if site has encharge and enpower
export function systemWithEnchargeAndEnpower() {
  return hasEnpower() && hasEncharge()
}

// Checks if site has encharge and is grid agnostic
export function systemWithEnchargeAndGridAgnostic() {
  return isSiteGridAgnostic() && hasEncharge()
}

// Checks if site is Ojas or PPs
export function isOjasOrPpsSite(check) {
  const isSiteHasOjasDevice = getSiteSettingsDetails().hasOjasDevice
  const isPPSApplicable = check ? !isWeb() && getDeviceDetails('ppsMode', 'ppsMode') : true
  return isPPSApplicable && isFeatureSupported(FEATURE_KEYS.OJAS) && isSiteHasOjasDevice
}

// Checks for hasEncharge and shows help profile select
export function showHelpProfileSelect() {
  return hasEncharge()
}

// Checks for no active feature to be shown for battery page
export function noActiveFeatureForBattery() {
  return !(showChargeFromGrid() || isSiteGridAgnostic() || showDischargeToGrid())
}

// Checks if batterys are calibrated
export function isBatteryCalibrationProgress() {
  return getSiteSettingsDetails().calibrationProgress
}

// Checks if a site supports multiple profiles
export function siteHasMultipleProfiles() {
  return showSavingsMode() || isSiteGridAgnostic()
}

export function isRestrictCFG() {
  return getSiteSettingsDetails().restrictCfg
}

export function isNem3Supported() {
  return getSiteSettingsDetails().isNEM3Supported
}

export function isNem3TariffSupported() {
  return getSiteSettingsDetails().isTariffNEM3
}

export function isNEM3Site() {
  return getSiteSettingsDetails().isNEM3Site || false
}

export function customScheduleEnabled() {
  return isFeatureSupported(FEATURE_KEYS.HEMS_EV_CUSTOM_SCHEDULE)
}

// Get accepted GIC disclaimer
export function getAcceptedGICDisclaimer() {
  return getSiteSettingsDetails().acceptedGICDisclaimer
}

// Get grid interconnection details
export function getGridInterconnectionDetails() {
  return getSiteSettingsDetails().gridInterconnectionDetails
}

export function isBatteryGridMode(mode) {
  return getBatteryProfileDetails().batteryGridMode === mode
}

export function isBatteryGridModeAvailable() {
  return getBatteryProfileDetails().batteryGridMode
}

// Show DTG card details
export function showDischargeToGrid() {
  return getBatteryProfileDetails()?.dtgControl?.show
}

export function dischargeToGridEnable() {
  return getBatteryProfileDetails()?.dtgControl?.enabled
}

// charge from grid schedule enabled
export function chargeFromGridScheduleEnabled() {
  return getBatteryProfileDetails().chargeFromGridScheduleEnabled
}

// NEM 3 site with PV + Battery + IQEVSE
export function nem3PVBatteryIQEVSESite() {
  return isNEM3Site() && siteHasPv() && siteHasBattery() && isEvseSite()
}

// NEM 3 site with PV + Battery
export function nem3PVBatterySite() {
  return isNEM3Site() && siteHasPv() && siteHasBattery() && !isEvseSite()
}

// NEM 3 site with PV + Battery +  IQ System Controller (HasEnpower)
export function nem3PVBatteryIQSystemControllerSite() {
  return isNEM3Site() && siteHasPv() && siteHasBattery() && hasEnpower()
}

export function isNEM3ExpOnlySite() {
  return isNEM3Site() && isBatteryGridMode(BATTERY_GRID_MODE.EXPORT_ONLY)
}

// Sites with Savings Profile and isIQGWScheduleSupportedSite true
export function IQGWScheduleSupportWithSavingsMode() {
  return isIQGWScheduleSupportedSite() && showSavingsMode()
}

export function showVLS() {
  return getSiteSettingsDetails()?.showVLS
}

export function showBatteryBackupPercentage() {
  return getSiteSettingsDetails()?.showBatteryBackupPercentage
}

export function showFullBackup() {
  return getSiteSettingsDetails()?.showFullBackup
}

export function isIQCollarPresentNotInstalled() {
  return getSiteSettingsDetails()?.isCollarPresentUninstalled
}
export function getProfileTutorialVideoURL() {
  return getBatteryProfileDetails().appTutorialUrl
}

export function isEvChargingModesSupported() {
  return getSiteSettingsDetails()?.isChargingModesEnabled
}

export function getGridConnectionType() {
  return getSiteSettingsDetails()?.gridConnectionType
}

export function isILNEMSite() {
  return isNEM3Site() && [8, 10].includes(getGridConnectionType())
}
